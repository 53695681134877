import React, { useEffect, useState } from 'react';
import { API } from './_functions'

export function FileManager(props) {

  const deleteFiles = () => false
  const uploadFiles = () => false
  const selectFiles = () => false

  
  const [fileList, setFileList] = useState([])
  const addFiles = (event) => {
    setFileList([...event.target.files])
  }

  const handleUpload = (pub) => {
    let fileInput = document.forms['file-upload-form']['file-upload-input']
    
    const formData = new FormData();

    let ins = fileInput.files.length;
    for (let i = 0; i < ins; i++) {
      formData.append("upload-file[]", fileInput.files[i]);
    }
    formData.append("public", pub)

    API({
      endpoint : '/api/projects/'+(props.data.project_slug || props.data.slug)+'/files',
      method: 'POST',
      body: formData,
      callback: () => {
        props.filesAddedCallback(fileInput.files, pub)
        fileInput.value = ''
        setFileList([])
      }
    })


  }

  const handleDelete = (pub) => {

    const getSelectValues = (select) => {
      var result = [];
      var options = select && select.options;
      var opt;

      for (var i=0, iLen=options.length; i<iLen; i++) {
        opt = options[i];

        if (opt.selected) {
          result.push(opt.value || opt.text);
        }
      }
      return result;
    }

    let selectedValues = getSelectValues(document.getElementById((pub ? 'public' : 'private') + '-files-select'))

    API({
      endpoint : '/api/projects/'+(props.data.project_slug || props.data.slug)+'/files',
      method: 'DELETE',
      body: {files:selectedValues, public:pub},
      callback: () => {
        props.filesDeletedCallback(selectedValues, pub)
      }
    })

  }

  const handlePrivateUpload = () => { handleUpload(false) }
  const handlePublicUpload = () => { handleUpload(true) }

  const handlePrivateDelete = () => { handleDelete(false) }
  const handlePublicDelete = () => { handleDelete(true) }

  return (
    <div className={["rm-file-uploader","modal", props.visible ? "is-active" : 'is-hidden'].join(' ')}>
    <div class="modal-background"></div>
    <div className="modal-card" style={{width: "80%"}}>
      
      <header className="modal-card-head">
        <p className="card-header-title">
          Manage files for [{ props.data.project_title || props.data.title }]
        </p>
        <button className="card-header-icon" aria-label="close" onClick={ props.toggleVisible }>
          <span className="icon">
            <i className="fas fa-window-close" aria-hidden="true"></i>
          </span>
        </button>
      </header>

      <section className="modal-card-body columns">
        
        { props.data.private_files.length > 0 && 
        <div className="select is-multiple column">
          <div className="is-fullwidth panel">
          <p className="panel-heading is-primary">Private files</p>
          <a className="panel-block">
            <select id="private-files-select" multiple size="5" style={{width: "100%"}}>
              { props.data.private_files.map((filename) => 
                <option key={filename} value={filename}>{filename}</option>
                ) }
            </select>
          </a>
          <a className="panel-block">
            <input className="button is-primary is-outlined is-fullwidth" type="button" value="Delete selected" onClick={handlePrivateDelete}/>
          </a>  
          </div>
        </div>
        }

        { props.data.public_files.length > 0 && 
        <div className="select is-multiple column">
          <div className="is-fullwidth panel">
          <p className="panel-heading is-primary">Public files</p>
          <a className="panel-block">
            <select id="public-files-select" multiple size="5" style={{width: "100%"}}>
              { props.data.public_files.map((filename) => 
                <option key={filename} value={filename}>{filename}</option>
                ) }
            </select>
          </a>
          <a className="panel-block">
            <input className="button is-primary is-outlined is-fullwidth" type="button" value="Delete selected" onClick={handlePublicDelete} />
          </a>
          </div>
        </div> }

        <div className="select is-multiple column">
        <form name="file-upload-form">
          <div className="file is-centered is-boxed is-success has-name is-fullwidth panel">
            <label className="file-label">
              <input name="file-upload-input" className="file-input" multiple type="file" onChange={addFiles} />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  Choose files to upload
                </span>
              </span>
              <div className="file-name-list">
              
                { fileList.map((file,i) => (
                  <a key={i} className="panel-block">
                  <span className="panel-icon">
                    <i className="fas fa-file" aria-hidden="true"></i>
                  </span>
                  { file.name }
                  </a>
                  ))
                }
              </div>
              { fileList.length>0 && 
              <a className="panel-block">
                <input className="button is-primary is-outlined is-fullwidth" type="button" value="Upload as private" onClick={handlePrivateUpload}/>
              </a> }
              { fileList.length>0 && 
              <a className="panel-block">
                <input className="button is-primary is-outlined is-fullwidth" type="button" value="Upload as public" onClick={handlePublicUpload} />
              </a> }
            </label>
          </div>
        </form>
        </div>
      </section>

           
     </div>
     </div>
  );
}


