import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from './components/App';
import { ProjectPage } from './components/ProjectPage'
import { InterfaceView } from './components/InterfaceView'
import { LoginView, Logout } from './components/LoginView'
import { PasswordReset } from './components/PasswordReset'
import { ValidateUser } from './components/ValidateUser'
import { Error404 } from './components/Error404'


ReactDOM.render(
  <BrowserRouter >
   <Routes>
      <Route path="/" element={<App />} >
        <Route path="passwordreset/:hash" element={<PasswordReset/>} />
        <Route path="validate/:hash" element={<ValidateUser/>} />
        <Route path="logout" element={<Logout/>} />
        <Route path="login" element={<LoginView />} />
        <Route path="projects" element={<ProjectPage />} />
        <Route path=":projectSlug/:interfaceSlug" element={<InterfaceView mode="production" />} />
        <Route path=":projectSlug/:interfaceSlug/test" element={<InterfaceView mode="test" />} />
        <Route path=":projectSlug/:interfaceSlug/safemode" element={<InterfaceView mode="safemode" />} />
        { (
          window.globals.getUserRole() == 'anonymous' 
          ? <Route path="" element={<Navigate to='/login' />} />
          : <Route path="" element={<Navigate to='/projects' />} />
          )
        }

        <Route path="*" element={<Error404/>} />
      </Route>
   </Routes>
  </BrowserRouter>,

  document.getElementById('root')
);

