import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import { API } from './_functions'
import { IconButton } from './mini-components'

import sanitizeHtml from 'sanitize-html';

var showdown  = require('showdown'), converter = new showdown.Converter({tables: true})


export function Wiki(props) {

  let params = useParams();

  //which window is visible
  const [address, setAddress] = useState('__all__') 
  const [content, setContent] = useState('')
  const [mode, setMode] = useState('read')
  const [browsingHistory, setBrowsingHistory] = useState(['__all__'])
  const [historyPointer, setHistoryPointer] = useState(0)


  const getWikiContent = (address) => {

    API({ endpoint: '/api/wiki/'+address, method: 'GET',
      callback: (data) => {
        
        if(data.type == 'page') { setContent(data.content) }
        if(data.type == 'collection') {
          const propercase = (s) => s[0].toUpperCase() + s.substring(1).toLowerCase()
          
          data.pages.sort((a, b) => {
            if(a.address < b.address) { return -1; }
            if(a.address > b.address) { return 1; }
            return 0})

          let content = ''
          let activeletter = null
          for(let page of data.pages) {
            if(page.address[0] != activeletter){
              content += activeletter ? '</ul>' : ''
              content += '<strong>'+page.address[0].toUpperCase()+'</strong>'
              content += '<ul>'
              activeletter = page.address[0]
            }
            content += '<li><a wikilink="'+page.address+'">'+propercase(page.address)+'</a></li>'
          }
          content += '</ul>'

          setContent(content)
        }

        
      }
    })
  }

  const editIsDisabled = () => {
    if(address == '__all__') return true
    return false
  }

  const handleBack = () => {
    setMode('read')
    setHistoryPointer((prev) => prev-1)
  }

  const handleForward = () => {
    setMode('read')
    setHistoryPointer((prev) => prev+1)
  }

  useEffect(() => {
    if(address != browsingHistory[historyPointer]) {
      setAddress(browsingHistory[historyPointer])
    }
  } , [historyPointer])
  
  useEffect(() => {
    document.getElementById('wiki-address').value = address
    getWikiContent(address)
    
    if(browsingHistory[historyPointer] != address) {
      setBrowsingHistory(prev => {
        if(historyPointer == prev.length-1){
          setHistoryPointer(prev.length)
          return [...prev, address]
        }

        setHistoryPointer((prev) => prev+1)
        return [...prev.slice(0, historyPointer+1), address]
        
        })
    }

    }, [address])

  const savePost = () => {
    if(mode == 'read'){
      setMode('edit')
      return
    }

    if(content == ""){
      deletePost()
      setMode('read') 
      return
    }

    const formData = new FormData();
    formData.append("content", content)

    API({
      endpoint: '/api/wiki/'+address,
      method: 'POST',
      body: formData,
      callback: (data) => {
        setMode('read') 
      }
    })
  }

  const deletePost = () => {
    API({
      endpoint: '/api/wiki/'+address,
      method: 'DELETE',
      callback: (data) => {
        setContent('') 
      }
    })
  }

  const handleChange = (event) => setContent(event.target.value)
  const handleAddressChange = (event) => { 
    setAddress(document.getElementById('wiki-address').value)
  }
  const handleAddressKeyDown = (event) => {
    if(event.key == 'Enter') handleAddressChange(event)
  }

  const displayContent = (dirtyHTML) => {

    const replace = (domNode, index) => {
      if(domNode.attribs && domNode.attribs.wikilink){
        return (<a
            onClick={(event) => {
              event.preventDefault() 
              setAddress(domNode.attribs.wikilink)
            }}
            >
            <span pointerEvents="none">
              {domNode.children[0].data} 
            </span>
          </a>)
      }

    }
  
    return <div className="wiki-content">{parse(converter.makeHtml(dirtyHTML), {'replace': replace})}</div>
 
  }

  
  return (
    <div className={["wiki", "card","is-fluid", props.hidden ? "is-hidden" : '', props.dual ? "is-dual-right" : ''].join(' ')}>
      <header className="card-header">
        <div className= "card-header-title">
          <div className="field is-horizontal">
          <fieldset className="field-body">  
            
              <div className="buttons field">
                <button className="button is-small" onClick={handleBack} disabled={historyPointer==0} >
                  <span className="icon is-small">
                    <i className="fas fa-arrow-left"></i>
                  </span>
                </button>

                <button className="button is-small"  onClick={handleForward} disabled={historyPointer==browsingHistory.length-1} >
                  <span className="icon is-small">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </button>

                <button className="button is-small" onClick={() => setAddress('__all__')} >
                  <span className="icon is-small">
                    <i className="fas fa-home"></i>
                  </span>
                </button>

                <button className="button is-small" onClick={savePost} disabled={editIsDisabled()}>
                  { mode == 'read' && <span className="icon is-small"><i className='fas fa-edit'></i></span> }
                  { mode == 'edit' && <span className="icon is-small"><i className='fas fa-save'></i></span> }
                </button>

                {/*<button className="button is-small" onClick={deletePost}>
                  <span className="icon is-small">
                    <i className="fas fa-trash"></i>
                  </span>
                </button>*/}
              </div>
            

            
              <div className="field has-addons">
                <p className="control ">
                  <input id="wiki-address" className="input is-small is-info" defaultValue={address} onKeyDown={handleAddressKeyDown} />
                </p>
                <div className="control">
                  <a className="button is-info is-small" onClick={handleAddressChange} >
                  Search
                  </a>
                </div>
              </div>
            </fieldset>
          </div>

        </div>
        <button className="card-header-icon" aria-label="close" onClick={props.toggleFunction}>
          <span className="icon">
            <i className="fas fa-window-close" aria-hidden="true"></i>
          </span>
        </button>
      </header>

      <div className="card-content">
        { mode == 'read' ? 
        (content ? displayContent(content) : <div>This wiki page is empty - you may add content by clicking <span><i className="fa fa-edit"></i></span> above.</div> )
        :
        <textarea className="textarea wiki-editor" value={content} onChange={handleChange} />
        }
      </div>

      
     </div>
  );
}


