import React, { useEffect, useState } from 'react';
import {BulmaInputText, BulmaTextArea} from './mini-components'
import { API } from './_functions'


export function ProjectEditor(props) {

  //TODO: All functionality 

  const [projectTitle, setProjectTitle] = useState(props.data.title)
  const [projectDescription, setProjectDescription] = useState(props.data.description)
  
  const [domainsVisibleTo, setDomainsVisibleTo] = useState([...props.data.domains_visible_to])
  const [permissionWhiteList, setPermissionWhiteList] = useState([...props.data.permission_whitelist])
  const [permissionBlackList, setPermissionBlackList] = useState([...props.data.permission_blacklist])

  const [interfaces, setInterfaces] = useState([...props.data.interfaces])

  const [admin, setAdmin] = useState(props.data.administered_by)


  const handleListChange = (event, setter) => {
    let listVal = event.target.value.split('\n')
    setter(listVal)
  }

  const handleInterfaceChange = (event, field, key) => {
    const booleanCorrect = (v) => (
      v == 'false' ? false :
      v == 'true' ? true:
      v)

    setInterfaces((prev) => prev.map(i => i.slug == key ? {...i, [field]: booleanCorrect(event.target.value)} : {...i}))
  }

  const handleAdminChange = (event) => {
    setAdmin(event.target.value)
  }


  const handleSave = (event) => {
    const requestBody = {
        title: projectTitle,
        description: projectDescription,
        domains_visible_to : domainsVisibleTo,
        permission_whitelist: permissionWhiteList,
        permission_blacklist: permissionBlackList,
        interfaces : interfaces,
        administered_by: admin 
    }
    console.log(requestBody)

    API({
      endpoint: '/api/projects/'+props.data.slug,
      method: 'PUT',
      body: requestBody,
      callback: () => window.location.reload(),
    })
  }


  return (
    <div className={["rm-project-editor","modal", props.visible ? "is-active" : 'is-hidden'].join(' ')}>
    <div class="modal-background"></div>
    <div className="modal-card" style={{width: "80%"}}>
      
      <header className="modal-card-head">
        <p className="card-header-title">
          Edit [{ projectTitle }]
        </p>
        
        <button className="card-header-icon" aria-label="close" onClick={ props.toggleVisible }>
          <span className="icon">
            <i className="fas fa-window-close" aria-hidden="true"></i>
          </span>
        </button>
      </header>

      <section className="modal-card-body">
      
        <BulmaInputText name="Project title" value={ projectTitle } onChange={ (e) => setProjectTitle(e.target.value) } />
        
        <BulmaTextArea name="Project description" value={ projectDescription } onChange={ (e) => setProjectDescription(e.target.value) } />

        <div className="field">
          <label class="field label"> Administrator for this project:</label>
          <div className="select">
            
            <select value={admin} onChange={handleAdminChange}>
              {props.data.possible_admins.map(u => (
                <option value={u}>{u}</option>
                ))}
            </select>
            
          </div>
        </div>

        <div className="field columns">
          <div className="column">
            <BulmaTextArea 
              name="Domains visible to" 
              value={ domainsVisibleTo.join('\n') } 
              onChange={(e) => handleListChange(e, setDomainsVisibleTo) } />
          </div>
          <div className="column">
            <BulmaTextArea 
              name="Permission whitelist" 
              value={ permissionWhiteList.join('\n') }
              onChange={(e) => handleListChange(e, setPermissionWhiteList) } />
          </div>
          <div className="column">
            <BulmaTextArea 
              name="Permission blacklist"
              value={ permissionBlackList.join('\n') } 
              onChange={(e) => handleListChange(e, setPermissionBlackList) } />
          </div>
        </div>

        { interfaces.map(int => (
          <div className="field" key={ int.slug }>
            <div className="divider is-info">View: /{ int.slug } </div>
            <BulmaInputText name="View title" value={ int.title } onChange={(e) => handleInterfaceChange(e, 'title', int.slug) } />
            <BulmaTextArea name="View description" value={ int.description } onChange={(e) => handleInterfaceChange(e, 'description', int.slug) } />

            <div className="field">
              <label class="field label">Cache:</label>
              <div className="select">
                
                <select value={ int.is_cached } onChange={(e)=>handleInterfaceChange(e, 'is_cached', int.slug)}>
                  <option value={false}>no</option>
                  <option value={true}>yes</option>
                </select>
                
              </div>
            </div>
          </div>

          ))}

      </section>

      <footer className="modal-card-foot">
      <button className="button is-success" onClick={handleSave} >Save</button>
      <button className="button" onClick={props.toggleVisible}>Cancel</button>
      </footer>

     
     </div>
     </div>
  );
}


