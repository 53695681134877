import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from "react-router-dom";

function SelectionHelper(props) {
    
  const selectAll = () => {
    props.controlNames.map(name => props.updater(name, true))
  }
  const selectNone = () => {
    props.controlNames.map(name => props.updater(name, false))
  }

  return (
    <div className="is-pulled-right selection-helper">
      <span className="icon-text has-text-primary" onClick={selectAll}>
        <a className="fas fa-plus-square" ></a>
      </span>
      &nbsp;
      <span className="icon-text has-text-primary" onClick={selectNone}>
        <a className="fas fa-minus-square" ></a>
      </span>
    </div>
    )
}


function CheckboxListInput(props) {
  
  const handleClick = (event) => {
    props.updater(event.target.name,  event.target.checked)
    props.triggerRefresh && props.RM.refresh()

  }

  return (
    <div className="control is-small">
      { props.label && <span className="label is-small is-pulled-left">{ props.label }</span> }
      { props.options.length >= 2 && <SelectionHelper controlNames={ props.options.map(option => props.name+'_'+option.name ) } updater={props.updater}  /> }
      { props.label && <div className="is-clearfix"></div> }
      { props.options.map((option,i) => (
        <div key={i}>
        <label className="b-checkbox checkbox label is-small">
          <input 
            name={ props.name+'_'+option.name }
            type="checkbox"
            onChange={handleClick}
            checked={ props.state[props.name+'_'+option.name] }
            />
          <span className="check"></span>
          <span className="control-label">
          { option.label }
          </span>
        </label>
        </div>
        ))}
    </div>)
}



function RadiobuttonListInput(props) {
  
  const handleClick = (event) => {
    props.updater(event.target.name, document.querySelector('input[name="'+event.target.name+'"]:checked').value)
    props.triggerRefresh && props.RM.refresh()
  }

  return (
    <div className="control is-small">
      { props.label && <span className="label is-small is-pulled-left">{ props.label }</span> }
      { props.label && <div className="is-clearfix"></div> }
      { props.options.map((option,i) => (
        <div key={i}>
        <label className="b-radio radio label is-small">
          <input
            name={ props.name }
            value={ option.name }
            onClick={handleClick}
            type="radio"
            checked={props.state[props.name] == option.name }
            />
          <span className="check"></span>
          <span className="control-label">
            { option.label }
          </span>
        </label>
        </div>
        ))}
    </div>)
}

function SelectInput(props) {
  
  const handleChange = (event) => {
    props.updater(props.name, event.target.value)
    props.triggerRefresh && props.RM.refresh()
  }

  return (
    <div className="control select is-small">
      <select
        name={props.name}
        onChange={handleChange}
        value={ props.state[props.name] }
        >
        { props.options.map((option,i) => (
          <option key={i} value={option.name}>
            {option.label}
          </option>
        )) }
      </select>
    </div>)
}

function NumberInput(props) {

  const handleChange = (event) => {
    props.updater(props.name, event.target.value)
    props.triggerRefresh && props.RM.refresh()
  }

  return (
    <div className="control is-small">
      <input 
        className="input"
        type="number"
        name={ props.name }
        placeholder={ props.placeholder }
        onChange={ handleChange }
        value={ props.state[props.name] } />
    </div>)
}

export function Sidebar(props) {

  const params = useParams()
  const location = useLocation()
  const [sideBarState, setSideBarState] = useState({})
  const controllerStateLocalstorageKey = 'controllers:('+params.projectSlug+','+params.interfaceSlug+','+props.test+')'


  useEffect(() => {
    if(!props.data) { return }

    if(props.RM.sidebarLoaded){
      console.log('State', sideBarState)
      localStorage.setItem(controllerStateLocalstorageKey, JSON.stringify(sideBarState))
      console.log('Saved slide', sideBarState)
      props.RM.setVar(sideBarState)
    }
  }, [sideBarState])


  useEffect(() => {
    if(!props.data) { return }

    let cstate = localStorage.getItem(controllerStateLocalstorageKey)
    let slide

    if(cstate) {
      slide = JSON.parse(localStorage.getItem(controllerStateLocalstorageKey))
    }
    
    let controllerStateDefault = {}
    for(let cg of props.data) {
      for(let ct of cg.controllers) {
        if(ct.type=='select' || ct.type=='radio') { 
          controllerStateDefault[ct.name] = ct.default || (0 in ct.c ? ct.c[0].name : null)
        }
        if(ct.type=='number') { controllerStateDefault[ct.name] = ct.default || 0 }
        if(ct.type=='checkbox') { ct.c.map(c =>  controllerStateDefault[ct.name+'_'+c.name] = true) }
      }
    }

    if(!cstate) {
      slide = controllerStateDefault
    }

    if(Object.keys(slide).length == 0) { slide = null }
    
    if(slide) {
      console.log('Loaded controller state from localStorage', slide, controllerStateDefault)
      setSideBarState(slide)

    } else {
      console.log('Loaded default controller state', slide)  
      setSideBarState(controllerStateDefault)
    }
  }, [props.data])



  const stateUpdater = (key,value) => {
    setSideBarState((prev) => ({...prev, [key]: value}))
  }

  return (
    <div className={["side-bar", props.visible ? "is-visible" : 'is-hidden'].join(' ')} >
    <form>
    {props.data && props.data.map((controllerGroup,icg) => (
      <div className="field" key={icg}>
        <label className="label">{ controllerGroup.label }</label>
        { controllerGroup.controllers.map((controller,ic) => (
          <div key={ic} className="controller-group" style={{'marginBottom': '.5em'}}>
          { controller.showLabel && <div className='label is-small'>{controller.label}</div> }
          { controller.type == 'select' && <SelectInput         RM={props.RM} label={controller.label} options={controller.c} name={controller.name} triggerRefresh={controller.triggerRefresh || false} state={sideBarState} updater={stateUpdater} />}
          { controller.type == 'checkbox' && <CheckboxListInput RM={props.RM} label={controller.label} options={controller.c} name={controller.name} triggerRefresh={controller.triggerRefresh || false} state={sideBarState} updater={stateUpdater} />}
          { controller.type == 'radio' && <RadiobuttonListInput RM={props.RM} label={controller.label} options={controller.c} name={controller.name} triggerRefresh={controller.triggerRefresh || false} state={sideBarState} updater={stateUpdater} />}
          { controller.type == 'number' && <NumberInput         RM={props.RM} label={controller.label} name={controller.name} placeholder={controller.label} triggerRefresh={controller.triggerRefresh || false} state={sideBarState} updater={stateUpdater} />}
          </div>
          ))
        }
      </div>
      )) }
    </form>
    </div>);
}


