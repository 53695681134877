import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../media/RM.png'
import { IconButton } from './mini-components'

export function Navbar(props) {

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleBurgerClick = (event) => {
    setDropdownVisible((prev) => !prev)
  }
  
  return (
      <nav className ="navbar is-hidden-touch">
        <div className="navbar-brand is-hidden-touch">
          <img className="navbar-logo" src={logo}/>
        </div>

        <div className="navbar-menu">
          <div className="navbar-start">
            <p className="navbar-item title is-5">
              { props.message }
            </p>
          </div>
        </div>

        <div className="navbar-end is-hidden-touch">
          { props.buttons !== null && 
            <div className="buttons navbar-item field">
            { props.buttons }
            </div>
          }

          
          { props.hasSearch && props.searchEffect ?
          <div className="navbar-item ">
            <p className="control has-icons-right">
            <input className="input" type="text" placeholder="Search" onChange={props.searchEffect}/>
            <span className="icon is-right">
              <i className="fas fa-search" aria-hidden="true"></i>
            </span>
            </p>
          </div>
            :
            ''
          }

          { props.menuItems.length > 1 &&

          
          <div 
            className={`navbar-item ${dropdownVisible ? "is-active" : ""}`} 
            onClick={handleBurgerClick}
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
          >
          
            <IconButton icon="fas fa-bars" className="navbar-link" />
            <div className="navbar-dropdown is-right">
            { props.menuItems.map(item => {
              let className = [item.className] || []
              return (
                (item.href && <Link key={item.label} className={className.concat(["navbar-item","is-link"]).join(' ')} to={item.href}>{ item.label }</Link>) ||
                (item.onClick && <a key={item.label} className={className.concat(["navbar-item","is-link"]).join(' ')} onClick={item.onClick}>{ item.label }</a>)
                )
            })
            }
            </div>
          </div>
          }

          {
            props.menuItems.length == 1 &&
            <div className="navbar-item">{(
                (props.menuItems[0].href && <Link key={props.menuItems[0].label} className="navbar-item is-link" to={props.menuItems[0].href}>{ props.menuItems[0].label }</Link>) ||
                (props.menuItems[0].onClick && <a key={props.menuItems[0].label} className="navbar-item is-link" onClick={props.menuItems[0].onClick}>{ props.menuItems[0].label }</a>)
                )}
            </div>
          }


        
        </div>
      </nav>
  );
  
}