import { API } from './_functions'

window.globals = {
  /*palette: [
    "rgb(255,35,26)",
    "rgb(205,180,120)",
    "rgb(0,74,129)",
    "rgb(145,192,0)",
    "rgb(0,170,255)",
    "rgb(178,0,214)",
    "rgb(14,124,123)",
    "rgb(46,97,113)",
    "rgb(249,188,155)",
    "rgb(34,29,35)",
    ],*/

  palette: [
    "#ff3505",
    "#ff9900",
    "#f2cd5d",
    "#b6be9c",
    "#006666",
    "#46c284",
    "#aafcb8",
    "#0099cc",
    "#85e0ff",
    "#4a4a49",
    "#45579a",
    "#9da9d3",
    "#aa4465",
    "#dd7596",
    "#830403",
    "#ff6b6c",
    "#f5b4a0"
  ],


  apiUrl: 'https://api.rednote.fi',
  //apiUrl: 'http://localhost:5000',
  socketIoUrl: 'https://api.rednote.fi',

  publicFileUrl: (filename) => {
    return `${window.globals.apiUrl}/api/projects/${window.globals.params.projectSlug}/files/${filename}`
  },  
  
  getUserRole: () => {
    const token = localStorage.getItem('auth-token')
    if(token === 'null' || !token) return 'anonymous'
    try{
      const tokenPayLoad = JSON.parse(window.atob(localStorage.getItem('auth-token').split('.')[1]))
      return tokenPayLoad.role || 'anonymous'
    }catch(e){
      return 'token error'
    }
    
  },

  params: null,

  //for debugging race conditions
  sleep: ms => new Promise(r => setTimeout(r, ms)), 

}


window.serverStorage = {
  getKey: (key) => {
    
    const slugify = ( text ) => {
    return text.toString()
      .normalize( 'NFD' )
      .replace( /[\u0300-\u036f]/g, '' ) 
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-'); 
    };

    let params = window.location.pathname.split('/').slice(-2)
    return params[0]+'__'+params[1]+'__'+slugify(key)
  },

  getItem: (key, callback) => {
    console.log('fetching '+key+ ' from serverStorage')
    callback = callback || ((d) => d)

    API({
      endpoint: '/api/serverstorage/'+window.serverStorage.getKey(key),
      method: 'GET',
      callback: (data) => {
        callback && callback(data.value)
      }
    })
  },

  setItem: (key, value, callback) => {
    console.log('setting '+key+ ' in serverStorage')
    const formData = new FormData();
    formData.append("value", value)
    callback = callback || new Function()

    API({
      endpoint: '/api/serverstorage/'+window.serverStorage.getKey(key),
      method: 'POST',
      body: formData,
      callback:callback
    })
  },

  removeItem: (key, callback) => {
    callback = callback || new Function()

    API({
      endpoint: '/api/serverstorage/'+window.serverStorage.getKey(key),
      method: 'DELETE',
      callback:callback
    })
  },

  keys: (callback) => {
    API({
      endpoint: '/api/serverstoragekeys/'+window.serverStorage.getKey(''),
      method: 'GET',
      callback:callback
    })
  }
}