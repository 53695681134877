import React from 'react';
import { Link } from "react-router-dom";
import { API } from './_functions';
//import DOMPurify from "dompurify";
import parse from "html-react-parser";



export function ProjectCard(props) {

  const handleDelete = (Event, data) => {
    
    let response = window.confirm('Really delete view "'+data.title+'"? This operation can not be undone.')
    if(!response){
      return
    }
    
    API({
      endpoint: '/api/projects/'+props.data.slug+'/interfaces/'+data.slug,
      method: 'DELETE',
      callback: () => props.removeView(props.data, data),
    })

  }

  const handleProjectDelete = () => {

    let response = window.confirm('Really delete project "'+props.data.title+'"? This operation can not be undone.')
    if(!response){
      return
    }
    
    API({
      endpoint: '/api/projects/'+props.data.slug,
      method: 'DELETE',
      callback: () => props.removeProject(props.data),
    })

  
  }

  const interFaceList = props.data.interfaces.filter((d) => d.published || ['administrator','staff'].includes(props.userRole)).map((d) => (
    <div key={d.slug} className="panel-element-view panel-block is-block">
    

      <Link to={ "/" + props.data.slug + "/" + d.slug + (['administrator','staff'].includes(props.userRole) ? "/test" : '') }>
      { ['administrator','staff'].includes(props.userRole) && !d.published  &&  <span className="tag is-info">unpublished</span>} { d.title } 
      </Link>
      
      { props.userRole == 'administrator' && 
      <span className="button-icon-delete has-text-link-dark is-pulled-right icon-is-right" onClick={(e) => handleDelete(e,d)}>
        <i className="fas fa-times" aria-hidden="true" ></i>
      </span>
      }
    
      { d.description && 
        <div className="field panel-block description">
      { parse(d.description) }
        </div>
      }
    </div>
  ))

  const formatTime = (time) => {
    return time.split('T')[0]
  }

  return (
    <section className="project-card is-block-desktop" >
      <div className="card">
      
      <header className="card-header">
        <p className="card-header-title">{ props.data.title } </p>
        <p className="card-header-icon"><time dateTime={ props.data.created_at }> { formatTime(props.data.created_at) }</time></p>


      </header>
      
      <div className="card-content">
        <div className="content">
          <p>{ props.data.description }</p>
          <nav className="panel">
            { interFaceList }
          </nav>
        </div>

        <div className="is-microscopic">
        { props.userRole == 'administrator' && (<p>project-id : {props.data.id}</p>) }
        { props.userRole == 'administrator' && (<p>administered by : {props.data.administered_by}</p>) }
        </div>
      </div>
      
    { props.userRole == 'administrator' && <footer className="card-footer">
      <a href="#" className="card-footer-item" onClick={props.uploadClick}>Files</a>
      <a href="#" className="card-footer-item" onClick={props.editClick}>Edit</a>
      <a href="#" className="card-footer-item" onClick={props.addViewClick}>Add view</a>
      <a href="#" className="card-footer-item" onClick={handleProjectDelete}>Delete</a>
    </footer> }
    </div>
    </section>
  );
}


