import { ServerErrorMessage } from './mini-components'
import socketIOClient from "socket.io-client";
import { API } from './_functions'

export function RedMachine()  {

	this.startLoading = () => null
	this.stopLoading = () => null

	this.params = {}
	
	this.syncdata = {}
	
	this.sync = function() { 
		this.emit('sync', this.syncdata)
	}

	this.setSync = function(obj) {
		this.syncdata = {...obj}
	}

	this.onJoin = (data) => { console.log('Another client has joined the room. Please overwrite RM.onJoin() with your own method')}
	this.onLeave = (data) => { console.log('Another client has left the room. Please overwrite RM.onLeave() with your own method')}
	this.onPing = (data) => { console.log('Room is being pinged. Please overwrite RM.onPing() with your own method')}
	this.onCustom = (data) => { console.log('Custom message received. Please overwrite RM.onCustom() with your own method')}
	this.onSync = () => { console.log('Syncdata received. Please overwrite RM.onSync() with your own method')}

	this.connectSocket = function(){
		this.socket = socketIOClient(window.globals.socketIoUrl);
		this.socket.on('joined', this.onJoin)
		this.socket.on('left', this.onLeave)
		this.socket.on('pinged', this.onPing)
		this.socket.on('custom-response', this.onCustom)
		this.socket.on('synced', (data) => {
			this.setSync(data)
			this.onSync(data)
			})
		}

	this.disconnectSocket = function(){
		if(this.socket) this.socket.disconnect()
	}

	this.emit = (name, data) => {
		data = data || {}
		data.params = {
			'projectSlug': this.params.projectSlug,
			'interfaceSlug': this.params.interfaceSlug,
			'test':this.params.test,
			'__state':this.sidebarVariables
			 }
		data['Authorization'] = localStorage.getItem('auth-token')
		this.socket.emit(name, data)
	}


	this.ListenUntil = (attribute,listenedValue,callback) => {
		let listnerId = setInterval(() => {
			if(this[attribute] == listenedValue) {
			clearInterval(listnerId)
			callback.call()
			}
		},0)
	}

	this.ListenUntilPerpetual = (attribute, listenedValue, callback) => {
		let listnerId = setInterval(() => {
			if(this[attribute] == listenedValue) {
				console.log('Listener triggered for ' + attribute)
				this[attribute] = !this[attribute]
				callback.call()
			}
		},0)
	}


	this.format = () => {
		//setting attributes to their default states
		//this.refresh = () => this.processRequest(user_refresh);
		this.refresh = () => null;
		this.init = () => this.refresh(); 
		this.resize = () => null;
		this.sidebarLoaded = false;
		this.sidebarValuesLoaded = false;
		this.sidebarVariables = {};
		this.serverError = false;
		this.errorDetails = {'stacktrace':'', 'variables':[]};
	}

	this.setVar = (obj) => {
		this.sidebarVariables = {...obj}
		this.sidebarValuesLoaded = true
	}

	this.format()
	
	this.graphAreaSection = (params) => {
		var all_params = params.split(" ");
		//var not_params = ['left','right','top','bottom'].filter(function(d){ return all_params.indexOf(d) < 0});
		
		if(!document.querySelector('#grapharea > .'+all_params.join('.'))) {
			let section = document.createElement('div')
			section.className = all_params.join(' ')
			document.getElementById('grapharea').appendChild(section)
		}
		window.$('#grapharea > .'+all_params.join('.')).empty()
		return window.$('#grapharea > .'+all_params.join('.'))
	}

	this.processRequest = (callback) => {
		
		this.startLoading()
		
		let $errorMessagePrompt = document.querySelector('.container.server-error-message')
		$errorMessagePrompt && $errorMessagePrompt.remove()

		let brainRequest = () =>  {

			let inputData = this.sidebarVariables 

			console.log('Sending to server:', inputData)

			API({
      			endpoint: '/api/projects/' + this.params.projectSlug + '/interfaces/' + this.params.interfaceSlug + '/run',
      			method: 'POST',
      			body: {'state': inputData, 'test': this.params.test},
      			callback: (data) => {
					this.stopLoading()
					this.serverError = false;
					this.errorDetails = {'stacktrace':'', 'variables':[]};
					callback(data)
      			},
      			errorHandler: (response) => {
					
      				this.stopLoading()
					
					if(!response.json){
						let errorMessage = 'Server timeout. This is a lot of data to crunch. Try again in a minute.'
						document.getElementById('grapharea').innerHTML = ServerErrorMessage(errorMessage)
						return
					}

					return response.json().then(data => {
						this.serverError = true
						console.log('Server error:', data)
						//this.errorDetails = data
						this.errorDetails['stacktrace'] = data['stacktrace']
						this.errorDetails['variables'] = data['variables']
						let errorMessage = data.error || 'unknown error'
   						document.getElementById('grapharea').innerHTML = ServerErrorMessage(errorMessage)      				
					})
					
      			}
    		})

		}
		
		console.log("Waiting for lock opening.")
		let listnerId = setInterval(() => {
			if(this.sidebarLoaded && this.sidebarValuesLoaded) {
				console.log("Lock opened", this.sidebarVariables)
				clearInterval(listnerId)
				brainRequest.call()
			}
		},0)


	}
}