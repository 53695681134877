import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { RedMachine } from "./RedMachine"
import { CodeOverlay } from './CodeOverlay'
//import { PluginFactory } from './PluginFactory'
import { Wiki } from './Wiki'
import { Sidebar } from './Sidebar'
import { Navbar } from './Navbar'
import { FileManager } from './FileManager'
import { Error404 } from './Error404'
import { IconButton } from './mini-components'
import { API, secureFilename } from './_functions'
import { v4 as uuidv4 } from 'uuid'



window.RM = new RedMachine()


//***************************SCRIPT INJECTION**********************//


function scopedEval(context, expr) {
  //rethink this. Is scoping required when everything is in the global context anyway
  const evaluator = Function.apply(null, [...Object.keys(context), 'expr', "return eval('expr = undefined;' + expr)"]);
  return evaluator.apply(null, [...Object.values(context), expr]);
  
}


//***************************MAIN WORKSPACE**********************//

function GraphArea(props){
  window.RM.startLoading = props.startLoading
  window.RM.stopLoading = props.stopLoading

  return (
    <div id="grapharea-container" className={props.hasSidebar ? 'with-sidebar' : 'without-sidebar'}>
      <div className={ ["loader-wrapper",  props.loading ? "is-active" : ''].join(" ") }>
        <div className="loader is-loading"></div>
      </div>
      <div id="grapharea" className={props.hasSidebar ? 'with-sidebar' : 'without-sidebar'}></div>
    </div>
    )
}

//************************* MAIN WORKSPACE END ********************//


export function InterfaceView(props) {



  //const [response, setResponse] = useState("");
  useEffect(() => {
    //window.RM.connectSocket()
    return () => window.RM.disconnectSocket();
  }, []);

  let params = useParams(); window.globals.params = params;
  const location = useLocation()
  const [error404, setError404] = useState(false)

  const [menuItems, setMenuItems] = useState([])
  
  const [editableCode, setEditableCode] = useState({})
  const [codeOverlayVisible, setCodeOverlayVisible] = useState(false)
  const toggleCodeOverlay = () => setCodeOverlayVisible((prev) => !prev)
  
  const [wikiVisible, setWikiVisible] = useState(false)
  const toggleWiki = () => setWikiVisible((prev) => !prev)

  //const [codeError, setCodeError] = useState({})
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [loadingSpinnerVisible, setLoadingSpinnerVisible] = useState(false)
  const [uiControls, setUiControls] = useState()

  const [isCached, setIsCached] = useState(false)

  const [title, setTitle] = useState('')

  const adminMode = window.globals.getUserRole()=='administrator'
 
  const [injectionIds, setInjectionIds] = useState([])

  const removeInjections = () => { 
    injectionIds.map(id => {
      let injection = document.getElementById(id)
      injection && injection.remove()
    })
    setInjectionIds([]) }

  //****error handling ****//


  window.onerror = function (msg, url, line, col, errorObj) {
    let eMessage = (
      '<pre>JAVASCRIPT SYNTAX ERROR\n'+
      msg+'\n'+
      errorObj.stack+'</pre>'
    )
    adminMode && (document.getElementById('grapharea').innerHTML = eMessage)
    removeInjections()
  };
  window.addEventListener(
    'unhandledrejection', 
    (promiseRejectionEvent) => {
      let eMessage = (
        '<pre>JAVASCRIPT ERROR\n'+
        promiseRejectionEvent.reason.message+'\n'+
        promiseRejectionEvent.reason.stack+'</pre>'
      )
      adminMode && (document.getElementById('grapharea').innerHTML = eMessage)
      removeInjections()
    })

  ///////////////////////////////
  
  const injectScript = (options) => {
    const script = document.createElement('script')
    script.onerror = () => {
      alert('script level error caught')
    }
    script.setAttribute('role', 'user-injection')
    script.innerHTML = options.code
    document.head.appendChild(script)
  }

  const injectStyle = (css) => {
    const style = document.createElement('style')
    style.setAttribute('role', 'user-injection')
    style.innerHTML = css
    document.head.appendChild(style)
  }

  const giveIdsToInjections = () => {
    
    const idLabler = (element) => {
      const id = 'injection-'+uuidv4();
      element.setAttribute('id', id)
      setInjectionIds((prev) => prev.concat([id]))
    }
    
    let _ //for some reason throws syntax error without assignment
    _ = [...document.querySelectorAll('[role="plugin-injection"]')].map(idLabler)
    _ = [...document.querySelectorAll('[role="user-injection"]')].map(idLabler)

  }

  /*script injection management end*/
  


  useEffect(() => { 
    if(uiControls) { 
      console.log('Loaded sidebar structure')
      window.RM.sidebarLoaded = true
      }
    },
    [uiControls]
    )

  useEffect(() => {
    
    /* removing injected scripts (plugins) and styles (all) then clearing the grapharea*/
    removeInjections()

    //document.getElementById('grapharea').innerHTML = ''
    //completely deleting node and adding a clone to unbind all stuff (highcharts)
    let elem = document.getElementById('grapharea')
    elem.parentNode.appendChild(elem.cloneNode())
    elem.remove()

    window.RM.format()
  
    let garbageMan = []

    
    API({
      endpoint: '/api/projects/' + params.projectSlug + '/interfaces/' + params.interfaceSlug + (['test','safemode'].includes(props.mode) ? '?test' : '') ,
      method: 'GET',
      errorHandler: (response) => {
        if(response.status == 404){ setError404(true) }
      },
      callback: (data) => {
        //setInjectionComponents(data)
        setFilemanagerData(data)
        setTitle(data.title)
        setMenuItems(data.siblings)
        setIsCached(data.is_cached)
        
        try{
          setSidebarVisible(JSON.parse(data.uicontrols).length>0)
          setUiControls(JSON.parse(data.uicontrols))
        }catch{
          setSidebarVisible(false)
        }
        
        
        document.querySelectorAll('[role="plugin-injection"]').forEach(e => e.remove())

        //injecting plugin css into head
        data.plugins.filter(plugin => plugin.el != 'script').map(plugin => {
          let el = document.createElement("style")
          el.setAttribute('role', 'plugin-injection')
          el.innerHTML = plugin.source
          document.head.appendChild(el)
          })

        //running plugin scripts with scoped eval
        const globalsBeforePlugins = Object.getOwnPropertyNames(window)
        data.plugins
          .filter(plugin => plugin.el == 'script')
          .map(plugin => {
            console.log("Running plugin", plugin)
            scopedEval(window, plugin.source)
          })
        const globalsAfterPlugins = Object.getOwnPropertyNames(window)
        garbageMan = globalsAfterPlugins.filter(name => globalsBeforePlugins.indexOf(name)==-1)


        //user script and user styles
        document.querySelectorAll('[role="user-injection"]').forEach(e => e.remove())
        injectStyle(data.styles)
        injectScript({'code': data.frontend})

        giveIdsToInjections()

        //redmachine needs to load only after sidebar actually has data
        window.RM.removeInjections = removeInjections
        window.RM.params = {...params, 'test': ['test','safemode'].includes(props.mode) }
        window.RM.setSync(data.initial_sync)
        window.RM.ListenUntilPerpetual('serverError', true, () => {
          setCodeOverlayVisible(true);
        })
        
        if(props.mode !== "safemode") window.RM.init()

        },
    })
  
    if(window.globals.getUserRole() == 'administrator') {
      API({ 
        endpoint: '/api/projects/' + params.projectSlug + '/interfaces/' + params.interfaceSlug + '/code',
        method: 'GET',
        callback: setEditableCode,
      })
    }
  

    setCodeOverlayVisible(false)
    //garbage collection
    return () => garbageMan.map(name => {
      console.log('deleted:', name)
      delete window[name]
      window.onresize = null
    })

  }, [location.key])


  //Filemanager

  const [filemanagerData, setFilemanagerData] = useState({'private_files':[], 'public_files':[], 'title':''})
  const [filemanagerVisible, setFilemanagerVisible] = useState(false)
  const handleUpload = (event, data) => {
    setFilemanagerVisible(true)
    setFilemanagerData(data)
  }



  const filesAddedCallback = (files, pub) => {
    let variableName = pub ? 'public_files' : 'private_files'
    setFilemanagerData((prev) => ({
      ...prev,
      [variableName] : [...files]
        .map(file => secureFilename(file.name))
        .filter(name => !prev[variableName].includes(name))
        .concat(prev[variableName])
      }))
  }

  const filesDeletedCallback = (files, pub) => {
    let variableName = pub ? 'public_files' : 'private_files'
    setFilemanagerData((prev) => ({
      ...prev,
      [variableName] : prev[variableName].filter((name) => !files.includes(name))
    }))
  }

  const truncateName = (s) => s.length > 45 ? s.substring(0,42)+'...' : s

  return ( error404
    ?
    <Error404 />
    :

  (
    <div className={["page"].join(' ') } >
      <Navbar
        message={truncateName(title)}

        buttons={adminMode ? [
          <IconButton key="1" onClick={window.RM.refresh} icon="fas fa-sync" />,
          <IconButton key="2" onClick={toggleCodeOverlay} icon="fab fa-js"/>,
          <IconButton key="3" onClick={toggleWiki} icon="fab fa-wikipedia-w"/>,
          <IconButton key="4" onClick={() => setFilemanagerVisible(p => !p)} icon="fas fa-file-upload"/>,
          ] : 
          window.globals.getUserRole() === 'staff' ?
          [
          <IconButton key="1" onClick={toggleWiki} icon="fab fa-wikipedia-w"/>,
          <IconButton key="2" onClick={window.RM.refresh} icon="fas fa-sync" />,
          ]
          :
          [
          <IconButton key="1" onClick={window.RM.refresh} icon="fas fa-sync" />,
          ]}
        
        menuItems={
          menuItems
            .map(sibling => ({
              'label': truncateName(sibling.title),
              'href':'/' + params.projectSlug +'/' + sibling.slug + (props.mode == 'test' ? '/test' : '')+(props.mode == 'safemode' ? '/safemode' : ''),
              'className':'menu-interface-link' 
            }))
            .concat([
              {'label':'Back to projects', 'href':'/projects', 'className':'menu-separator-above' },
              {'label':'Logout', 'href':'/logout'}
            ])
          }
        />
      
      <GraphArea 
        hasSidebar={sidebarVisible}
        loading={loadingSpinnerVisible}
        startLoading={()=>setLoadingSpinnerVisible(true)}
        stopLoading={()=>setLoadingSpinnerVisible(false)}
        />

      <Sidebar test={ ['test','safemode'].includes(props.mode) } data={ uiControls } RM={ window.RM } visible={sidebarVisible} />

    { adminMode && <CodeOverlay 
        dual={codeOverlayVisible && wikiVisible}
        hidden={!codeOverlayVisible}
        code={editableCode}
        toggleFunction={toggleCodeOverlay}
        RM={ window.RM }
        errorDetails={window.RM.errorDetails}
        isCached={ isCached }
        /> }

    { wikiVisible && <Wiki 
        dual={ codeOverlayVisible && wikiVisible }
        hidden={!wikiVisible}
        toggleFunction={toggleWiki}
        /> }

    { filemanagerVisible && <FileManager 
        data={filemanagerData}
        visible={filemanagerVisible}
        toggleVisible={() => setFilemanagerVisible((prev) => !prev)} 
        filesAddedCallback={filesAddedCallback}
        filesDeletedCallback={filesDeletedCallback}
        /> }

    </div>
  ));
}


