import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import logo from '../media/RM.png'
import { API } from './_functions'
import { Koriste } from './Koriste'


export function PasswordReset(props) {

  const [errorMessage, setErrorMessage] = useState('')
  let _


  function handleReset(e) {
    if(!document.getElementById('loginform')[0].checkValidity()){
      _ = !document.getElementById('loginform')[0].reportValidity()
    }
    
    e.preventDefault();

    const formData = new FormData(document.getElementById('loginform'))
    const hash = window.location.pathname.split('/').pop()

    API({
      endpoint: '/api/passwordreset/'+hash,
      method: 'POST',
      body: formData,
      callback: (data) => {
        setErrorMessage('')
        localStorage.setItem('auth-token', data.token)
        window.location.replace('/projects'); 
      },
      errorHandler: (response) => {
        response.json().then((msg) => { setErrorMessage('Invalid credentials - '+msg.message+'.' ) })
      }
    })
  }


  return (
    <div className="page">
    <Koriste />
    <div className="modal is-active">
     <form id="loginform" autocomplete="off">
     <div className="modal-card">
      <header className="modal-card-head modal-card-plain">
        <img id="logo" src={logo} style={{height:'1.5rem'}} />

      </header>
      <section className="modal-card-body">
      
        <div className="field">
          <p className="control has-icons-left">
            <input className="input" type="password" placeholder="Enter a new password" name ="new-password" required/>
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </p>
        </div>

        {errorMessage ? 
        <div className="notification is-danger is-light">
        <div className="columns">
        <div className="column is-1">
        <span className="icon-text">
          <span className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
        </span>
        </div>
        <div className="column">
          {errorMessage}
        </div>
        </div>
        </div>
        : ''}

      </section>
        <footer className="modal-card-foot modal-card-plain">
        <div className="field">
          <div className = "buttons">
            <button className="button is-primary" onClick={handleReset}>Reset password</button>
          </div>
        </div>
        </footer>
      </div>
      </form>
    </div>
    </div>
  );
}


