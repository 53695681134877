import React, { useState, useEffect } from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import { API, secureFilename } from './_functions'

import { ProjectCard } from './ProjectCard'
import { Navbar } from './Navbar'
import { Koriste } from './Koriste'
import { FileManager } from './FileManager'
import { ProjectEditor } from './ProjectEditor'
import { ViewAdder } from './ViewAdder'
import { ProjectAdder } from './ProjectAdder'
import { Wiki } from './Wiki'
import { IconButton } from './mini-components'


export function ProjectPage(props) {

  //**************************PAGE DATA*******************************//

  const [projects, setProjects] = useState([]);
  const [ready, setReady] = useState(false)
  const [userRole, setUserRole] = useState(window.globals.getUserRole())

  //onko projektikoodi-inputti näkyvissä myös, vaikka muita projekteja jo olisi
  const [accessCodeOverrideVisible, setAccessCodeOverride] = useState(false)

  const handleKeyDown = (event) => { 
    if(event.code=='KeyQ' && event.ctrlKey) setAccessCodeOverride((prev) => !prev)
  }  

  useEffect(() => {

    API({
      endpoint: '/api/projects',
      method: 'GET',
      callback: (data) => {
        setReady(true)
        setProjects(data)
      },
      errorHandler: (response) => {
        if(response.status==403) window.location.replace("/login?next="+encodeURIComponent(window.location.href));
      }
    })

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);

  }, [])

  //**************************SEARCHBAR*******************************//


 const searchObj = (obj, term, searchableKeys) => {
    term = term.toLowerCase()
    let found=false;

    for(let key in obj){
      if(key == 'interfaces') {
        for(let i of obj.interfaces){ found |= searchObj(i, term, searchableKeys) }
      }

      if(obj[key] && typeof obj[key].toLowerCase === 'function') {
        if(searchableKeys.includes(key)) found = found || obj[key].toLowerCase().includes(term)
      }

      if(found) break
    }
    return found


  }

  const [searchFilter, setSearchFilter] = useState('')
  const handleSearch = (event) => {
    setSearchFilter(event.target.value.toLowerCase())
  }

  //*************************JOIN WHITELIST*****************************//


  const [projectCode, setProjectCode] = useState('')
  const joinWhiteList = (event) => {
    API({
      endpoint: '/api/joinwhitelist/' + projectCode.trim(),
      method: 'POST',
      callback: (data) => {
        window.location.replace("/projects")
      },
      errorHandler: (response) => {
        alert('does not exist')
      }
    })
  }


  //**************************FILEMANAGER*******************************//

  const [filemanagerData, setFilemanagerData] = useState({'private_files':[], 'public_files':[], 'title':''})
  const [filemanagerVisible, setFilemanagerVisible] = useState(false)
  const handleUpload = (event, data) => {
    setFilemanagerVisible(true)
    setFilemanagerData(data)
  }

  const filesAddedCallback = (files, pub) => {
    let variableName = pub ? 'public_files' : 'private_files'
    setFilemanagerData((prev) => ({
      ...prev,
      [variableName] : [...files]
        .map(file => secureFilename(file.name))
        .filter(name => !prev[variableName].includes(name))
        .concat(prev[variableName])
      }))
  }

  const filesDeletedCallback = (files, pub) => {
    let variableName = pub ? 'public_files' : 'private_files'
    setFilemanagerData((prev) => ({
      ...prev,
      [variableName] : prev[variableName].filter((name) => !files.includes(name))
    }))
  }

  //**************************PROJECT EDITOR*******************************//

  const [projectEditorData, setProjectEditorData] = useState()
  const [projectEditorVisible, setProjectEditorVisible] = useState(false)
  
  const handleEdit = (event, data) => {
    //invoked when "Edit Details" is clicked
    setProjectEditorVisible(true)
    setProjectEditorData(data)
  }

  const editorHandleSave = () => (null)

  //**************************VIEW ADDER*******************************//

  
  const [viewAdderVisible, setViewAdderVisible] = useState(false)
  const [viewAdderData, setViewAdderData] = useState([])
  const [viewAdderAnchor, setViewAdderAnchor] = useState({})

  const handleAddView = (event, data) => {
    setViewAdderVisible(true)
    setViewAdderAnchor(data)
  }

  const handleRemoveProject = (project) => {
    setProjects((prev) => prev.filter(proj => proj.slug != project.slug))
  }

  const handleRemoveView = (project, view) => {
    setProjects((prev) => {
      for(let proj of prev) {
        if(proj.slug == project.slug) {
          proj.interfaces = proj.interfaces.filter(v => v.slug != view.slug)
          break
        }
      }
      return [...prev]
    })
  }

  const [wikiVisible, setWikiVisible] = useState(false)
  const toggleWiki = () => {
    if(!wikiVisible) window.$('html, body').animate({ scrollTop: 0 }, 1200);
    setWikiVisible(!wikiVisible)
  }

  const [projectAdderVisible, setProjectAdderVisible] = useState(false)



  return (
    <div className="page">
    <Koriste />
    <Navbar 
      hasSearch = {projects.length > 4}
      searchEffect={handleSearch} 
      menuItems={[{'label':'Logout', 'href':'/logout'}]} 
      buttons={ userRole == 'administrator' ? [
          <IconButton key="1" onClick={toggleWiki} icon="fab fa-wikipedia-w"/>,
          <IconButton key="2" onClick={()=> setProjectAdderVisible(true)} icon="fas fa-plus"/>,
          ] : userRole == 'staff' ? [
          <IconButton key="1" onClick={toggleWiki} icon="fab fa-wikipedia-w"/>,
          ] : []}
      />

    <Wiki 
        dual={false}
        hidden={!wikiVisible}
        toggleFunction={toggleWiki}
        />

    <div className='project-card-container container'>
 
    <section className="hero">
      <div className="hero-body">
        <p className="title">
          Your machines are { ready ? 
            <span className="has-text-primary">ready.</span> 
            :
            <span className="has-text-warning">loading...</span>
            }
        </p>
        <p className="subtitle">
          { ready ? "Go ahead and explore." : "Please wait for a while."}
        </p>
      </div>
    </section>
    
    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 900: 2}}>
    <Masonry gutter="5px">
    {  
      projects
        .filter(d => searchObj(d,searchFilter,['title', 'description', 'created_at']))
        .map((d,i) => 
          <ProjectCard 
            key={i}
            data={d}
            uploadClick={(event) => handleUpload(event, d)}
            editClick={(event) => handleEdit(event, d)} 
            addViewClick={(event) => handleAddView(event,d)}
            removeView={handleRemoveView}
            removeProject={handleRemoveProject}
            userRole={userRole}
            />
          )
    }
    

    { ready && projects.length > 0 && projects.filter(d => searchObj(d,searchFilter,['title', 'description', 'created_at'])).length == 0 ?
      <section className="block" >
      <div className="notification is-warning">
      No projects found with search '{searchFilter}'
      </div>
      </section>
      :
      ''
    }

    { ready && (projects.length == 0 || accessCodeOverrideVisible) ?
      <section className="block" >
      <div className="notification card">
        { projects.length == 0 ?
        <div className="field">
        No machines are currently available for this account. If you have an access code you may enter it here:
        </div>
        :
        <div className="field">
        Add another machine with an access code:
        </div>
        }
        
        <div className="field has-addons">
        <p className="control has-icons-right">
          <input className="input" type="text" placeholder="Paste code here" onChange={(event) => setProjectCode(event.target.value)}/>
          <span className="icon is-right">
            <i className="fas fa-code" aria-hidden="true"></i>
          </span>
        </p>
        <div className="control">
        <a className="button is-primary" onClick={joinWhiteList}>
          Give me access!
        </a>
        </div>
        </div>

      </div>
      </section>
      :
      ''
    }

    </Masonry>
    </ResponsiveMasonry>

    
    
    { userRole == 'administrator' && filemanagerVisible && 
      <FileManager 
        data={filemanagerData}
        visible={filemanagerVisible}
        toggleVisible={() => setFilemanagerVisible((prev) => !prev)} 
        filesAddedCallback={filesAddedCallback}
        filesDeletedCallback={filesDeletedCallback}
        /> }
    
    { userRole == 'administrator' && projectEditorVisible &&
      <ProjectEditor 
        data={projectEditorData}
        visible={projectEditorVisible}
        toggleVisible={() => setProjectEditorVisible((prev) => !prev)}
        handleSave={editorHandleSave}
      /> }

    { userRole == 'administrator' && viewAdderVisible && 
      <ViewAdder 
        anchor={viewAdderAnchor}
        data={projects}
        visible={viewAdderVisible}
        toggleVisible={() => setViewAdderVisible((prev) => !prev)}
      />
    }

    { userRole == 'administrator' && projectAdderVisible && 
      <ProjectAdder 
        visible={projectAdderVisible}
        toggleVisible={() => setProjectAdderVisible((prev) => !prev)}
      />
    }



    </div>

    </div>
  );
}


