import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import logo from '../media/RM.png'
import { API } from './_functions'
import { Koriste } from './Koriste'


export function ValidateUser(props) {

  const [errorMessage, setErrorMessage] = useState('')


  function handleValidate(e) {
    const hash = window.location.pathname.split('/').pop()

    API({
      endpoint: '/api/users/validate/'+hash,
      method: 'GET',
      callback: (data) => {
        setErrorMessage('')
        localStorage.setItem('auth-token', data.token)
        window.location.replace('/login'); 
      },
      errorHandler: (response) => {
        console.log('handler reached')
        response.json().then((msg) => { setErrorMessage('This user validation link expired or never existed.') })
      }
    })
  }


  return (
    <div className="page">
    <Koriste />
    <div className="modal is-active">
     
     <div className="modal-card">
      <header className="modal-card-head modal-card-plain">
        <img id="logo" src={logo} style={{height:'1.5rem'}} />

      </header>
      <section className="modal-card-body">
      
        <div className="field">
        Please click on the button below to validate your account. You will be redirected to login.

        {errorMessage ? 
        <div className="notification is-danger is-light">
        <div className="columns">
        <div className="column is-1">
        <span className="icon-text">
          <span className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
        </span>
        </div>
        <div className="column">
          {errorMessage}
        </div>
        </div>
        </div>
        : ''}

        </div>

      </section>
        <footer className="modal-card-foot modal-card-plain">
        <div className="field">
          <div className = "buttons">
            <button className="button is-primary" onClick={handleValidate}>Validate.</button>
          </div>
        </div>
        </footer>
      </div>
     
    </div>
    </div>
  );
}


