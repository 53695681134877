import React, { useEffect, useState } from 'react';
import {BulmaInputText, BulmaTextArea} from './mini-components'
import { API } from './_functions'

const slugify = require('slugify')

export function ViewAdder(props) {

  const [viewSlug, setViewSlug] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [templateAddress, setTemplateAddress] = useState('')

  const handleAddView = (event) => {

    const formData = new FormData();

    formData.append("title", title)
    formData.append("description", description)
    formData.append("template", templateAddress)

    API({
      endpoint: '/api/projects/'+props.anchor.slug+'/interfaces/'+viewSlug,
      method: 'POST',
      body: formData,
      callback: (data) => {
        window.location.reload()
      }
    })

  }

  return (
    <div className={["rm-view-adder","modal", props.visible ? "is-active" : 'is-hidden'].join(' ')}>
    <div class="modal-background"></div>
    <div className="modal-card" style={{width: "80%"}}>
      
      <header className="modal-card-head">
        <p className="card-header-title"> Add view to { props.anchor.title }</p>
        <button className="card-header-icon" aria-label="close" onClick={ props.toggleVisible }>
          <span className="icon">
            <i className="fas fa-window-close" aria-hidden="true"></i>
          </span>
        </button>
      </header>

      <section className="modal-card-body">

      <BulmaInputText name="View title" value={title} onChange={(event) => {
        setTitle(event.target.value)
        setViewSlug(slugify(event.target.value))
      }} />
      <BulmaInputText name="View slug" value={viewSlug} disabled/>

      <BulmaTextArea name="Description" value={description} onChange={(event) => setDescription(event.target.value)}/>

      <div className="field">
        <label className="label">Template:</label>
        <div className="control">
        <div class="select">
        <select onChange={(e) => setTemplateAddress(e.target.value)}>
          <option value=""> * no template * </option>
          { props.data.map(
            project => project.interfaces.map(view => (
              <option key={project.slug+"/"+view.slug} value={project.slug+"/"+view.slug}>{project.title} - {view.title}</option>
              )
              ))}
        </select>
        </div>
        </div>
      </div>
    
      </section>

      <footer class="modal-card-foot">
      <button class="button is-success" onClick={handleAddView} >Add view</button>
      <button class="button" onClick={props.toggleVisible}>Cancel</button>
      </footer>

     
     </div>
     </div>
  );
}


