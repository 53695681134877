import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import logo from '../media/RM.png'
import { Koriste } from './Koriste'
import { API } from './_functions'

export function Logout(){
  localStorage.removeItem('auth-token')
  return <Navigate to="/login" />
}



export function LoginView(props) {

  const [errorMessage, setErrorMessage] = useState('')

  const [isFirstVisit, setIsFirstVisit] = useState(true)

  useEffect(()=> {
    setIsFirstVisit(localStorage.getItem('has-visited') ? false : true)
    localStorage.setItem('has-visited', true)
  },[]) 

  let _

  function handleLogin(e) {
    
    if(!document.getElementById('loginform')[0].checkValidity()){
      _ = !document.getElementById('loginform')[0].reportValidity()
    }
    
    e.preventDefault();

    const formData = new FormData(document.getElementById('loginform'))

    API({
      endpoint: '/api/users/login',
      method: 'POST',
      body: formData,
      callback: (data) => {
        setErrorMessage('')
        localStorage.setItem('auth-token', data.token)
        const urlParams = new URLSearchParams(window.location.search);
        
        //redirect to url var "next" if it exists, otherwise go to projects page
        if(urlParams.has('next')) {
          window.location.replace(urlParams.get('next'));
        }
        else{
          window.location.replace('/projects'); 
        }
      },
      errorHandler: (response) => {
        response.json().then((msg) => { setErrorMessage('Invalid credentials - '+msg.message+'.' ) })
      }
    })
  }

  function handleRegister(e) {
    if(!document.getElementById('loginform')[0].checkValidity()){
      _ = !document.getElementById('loginform')[0].reportValidity()
    }
    e.preventDefault();    

    const formData = new FormData(document.getElementById('loginform'))

    API( {
      endpoint: '/api/users/register',
      method: 'POST',
      body: formData,
      callback: (data) => {
        setErrorMessage('A validation email has been sent to '+document.getElementById('username-field').value+'. Please check your email and follow the link within.')
      },
      errorHandler: (response) => {
        if (response.status==401){
          response.text().then((msg) => {
            setErrorMessage(<span>This username already is validated. No need to register twice.</span>)
          })
        }
        else{
          setErrorMessage(<span>Unknown error.</span>)
        }
      }
    })
  }

  function handlePasswordReset(e) {
    e.preventDefault();
    
    const formData = new FormData()
    const username = document.getElementById('username-field').value
    formData.append('username', username)
    
    API({
      endpoint: '/api/passwordreset',
      method: 'POST',
      body: formData,
      callback: (data) => {
        setErrorMessage('A password reset email has been sent to '+username+'. Please check your email and click the link within.')
      },
      errorHandler: (response) => { setErrorMessage('Unknown error.') }
      })
  }


  return (
    <div className="page">
    <Koriste />
    
    { isFirstVisit && <section className="user-info">
    <div className="notification is-info">
      <p>Redmachine on uudistunut ja löytyy jatkossa täältä, eli osoitteesta <b>redmachine.rednote.fi</b></p>

      <p>Tämä ei edellytä käyttäjiltä mitään toimenpiteitä. Vanhat käyttäjät, salasanat ja käyttäjäoikeudet toimivat kuten ennenkin</p>
    </div>
    </section>
    }

    <div className="modal is-active">
     <form id="loginform">
     <div className="modal-card">
      <header className="modal-card-head modal-card-plain">
        <img id="logo" src={logo} style={{height:'1.5rem'}} />

      </header>
      <section className="modal-card-body">
      
        <div className="field">
          <p className="control has-icons-left">
            <input className="input" id="username-field"  type="email" placeholder="Email" name="username" required />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left">
            <input className="input" id="password-field" type="password" placeholder="Password" name ="password" required/>
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </p>
        </div>

        {errorMessage ? 
        <div className="notification is-danger is-light">
        <div className="columns">
        <div className="column is-1">
        <span className="icon-text">
          <span className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
        </span>
        </div>
        <div className="column">
          {errorMessage}
        </div>
        </div>
        </div>
        : ''}
 
      </section>
        <footer className="modal-card-foot modal-card-plain">
        <div className="field">
          <div className = "buttons">
            
            <button 
              className="button is-primary"
              onClick={handleLogin}>
                Login
            </button>
            
            <div className="divider is-info is-vertical">or</div>
            
            <button 
              className="button is-primary" 
              onClick={handleRegister}>
                Register
            </button>

            <button 
              className="button is-outlined is-pulled-right" 
              onClick={handlePasswordReset}>
                Forgot password?
            </button>
          </div>
        </div>
        </footer>
      </div>
      </form>
    </div>
    </div>
  );
}


