import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { Koriste } from "./Koriste";

import './App.scss';
import './_global'


function App() {

  return (
    <div className="RedMachine">
    <Outlet />
    </div>
  );
}

export default App;
