import React, { useEffect, useState } from 'react';
import {BulmaInputText, BulmaTextArea} from './mini-components'
import { API } from './_functions'

const slugify = require('slugify')

export function ProjectAdder(props) {

  const [slug, setSlug] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const handleAddProject = (event) => {

    const formData = new FormData();

    formData.append("title", title)
    formData.append("description", description)
    formData.append("slug", slug)

    API({
      endpoint: '/api/projects/'+slug,
      method: 'POST',
      body: formData,
      callback: (data) => {
        sessionStorage.clear()
        window.location.reload()
      }
    })

  }

  return (
    <div className={["rm-view-adder","modal", props.visible ? "is-active" : 'is-hidden'].join(' ')}>
    <div className="modal-background"></div>
    <div className="modal-card" style={{width: "80%"}}>
      
      <header className="modal-card-head">
        <p className="card-header-title"> New project </p>
        <button className="card-header-icon" aria-label="close" onClick={ props.toggleVisible }>
          <span className="icon">
            <i className="fas fa-window-close" aria-hidden="true"></i>
          </span>
        </button>
      </header>

      <section className="modal-card-body">

      <BulmaInputText name="Project title" value={title} onChange={(event) => {
        setTitle(event.target.value)
        setSlug(slugify(event.target.value))
      }} />
      <BulmaInputText name="Project slug" value={slug} disabled/>

      <BulmaTextArea name="Description" value={description} onChange={(event) => setDescription(event.target.value)}/>

      </section>

      <footer className="modal-card-foot">
      <button className="button is-success" onClick={handleAddProject} >Create new project</button>
      <button className="button" onClick={props.toggleVisible}>Cancel</button>
      </footer>

     
     </div>
     </div>
  );
}


