export function API(options){
	/*
	*	Calls the api
	*	options.body should be an object or formdata (correct headers will be updated)
	*	if options.method is GET or HEAD, no body will be sent
	*	default options.method is "GET"
	*/

	if(!options.endpoint) throw('No endpoint defined in API call')
	options.method = options.method || 'GET'
	options.callback = options.callback || ((data) => null)
	options.errorHandler = options.errorHandler || ((data) => null)
	options.body = options.body || {}

	const bodyIsFormData = options.body instanceof FormData

	const url = window.globals.apiUrl + options.endpoint

	let fetchOptions = {
		'method': options.method ,
		'headers': { 
			'Authorization': localStorage.getItem('auth-token'),
			'Content-Type': 'application/json'
		},
		'body': bodyIsFormData ? options.body : JSON.stringify(options.body),
	}
	if(bodyIsFormData){	delete fetchOptions.headers['Content-Type']	}
	if(['GET','HEAD'].includes(options.method)){ delete fetchOptions.body }

	fetch(url, fetchOptions)
    	.then(response => {
			
      		if(!response.ok){
   				console.error('API '+options.method+' fetch to '+options.endpoint+' failed with code '+ response.status, response)
   				return options.errorHandler(response)
      			}

      		return response.json().then(data => {
      			options.callback(data)
      			})
      		}
      	)
      	/*.catch((error) => {
  			return options.errorHandler(error)
		});*/
}

export const secureFilename = (s) => s.normalize('NFKD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s\./_\-])/g, '').replace(/[\s/]/g,'_')