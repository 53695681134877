import React, { useEffect, useState } from 'react';
import { API } from './_functions'
import { Navbar } from './Navbar'
import { Koriste } from './Koriste'
import { Link } from "react-router-dom";

export function Error404(props) {

	return (
	<div className="page">
    <Koriste />
    <Navbar 
      hasSearch = {false}
      menuItems={[]} 
      buttons={[]}
      />

    <div className='container error-404-container'>
    <section className="hero">
	  <div className="hero-body">
	    <p className="title">
	      404 Error
	    </p>
	    <p className="subtitle">
	      Sorry, but this content does not exist. <Link to="/projects">Go back.</Link>
	    </p>
	  </div>
	</section>
	</div>

    </div>)

}