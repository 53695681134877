import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-css'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import 'ace-builds/webpack-resolver'

import { API } from './_functions'

function ErrorDisplay(props) {

  const typeMapper = (type) => {
      //todo finish
    if(type=='module') return 'fa-database'
    if(type=='list') return 'fa-brackets'
    if(type=='dict' || type=='collections.defaultdict') return 'fa-book'
    if(type=='NoneType') return 'fa-empty-set'
    if(type=='pandas.core.frame.DataFrame') return 'fa-table'
    return 'fa-calculator'
  }

  const [expanded, setExpanded] = useState(new Set())
  const toggleExpanded = (value) => {
    setExpanded((prev) => {

      if(prev.has(value))
        prev.delete(value)
      else
        prev.add(value)

      return new Set(prev)
    })
  }
  
  return (
    <div className={["card-content", props.error.stacktrace=='' ? 'is-hidden' : ''].join(' ')}>
    <div style={ {display:'flex', flexDirection:"row", flexWrap: 'wrap', width:"100%" } } className={["content", props.error.stacktrace=='' ? 'is-hidden' : ''].join(' ')}>
      
      <code style={ {width:"100%" }} >
        <pre>
        {props.error.stacktrace}
        </pre>
      </code>

      { props.error.variables.map( variable => (
        
        <div key={variable.varname} style={ {width:"33%" }} >
        
        <a onClick={() => toggleExpanded(variable.varname)}>
          <span className="icon">
            <i className={["fas", typeMapper(variable.type)].join(' ')} aria-hidden="true"></i>
          </span>
          {variable.varname} - {variable.type}
        </a>
        { expanded.has(variable.varname) && 
        <pre className="pre-debug-variable">
          {variable.value}
        </pre>
        }
        
        </div>
      )) } 
    </div>
    </div>
    )
}


export function CodeOverlay(props) {

  let params = useParams();

  //which window is visible
  const [editorState, setEditorState] = useState('backend')
  
  //currently edited code
  const [currentCode, setCurrentCode] = useState({'mode':'python','value':''})
    
  //work in progress code
  const [wipCode, setWipCode] = useState({})

  //const [error, setError] = useState({'stacktrace':[],'variables':[]})
  const [error, setError] = useState(props.errorDetails)


  const getCurrentCode = (from) => {
    let codeObj = {'mode': null, 'value': null}
    if(editorState == 'backend') { codeObj = {'mode':'python', 'value': from.backend } }
    if(editorState == 'frontend') { codeObj = {'mode':'javascript', 'value': from.frontend } }
    if(editorState == 'styles') { codeObj = {'mode':'css', 'value': from.styles } }
    if(editorState == 'uicontrols') { 
      try{
        from.uicontrols = JSON.stringify(JSON.parse(from.uicontrols), null, 2);
      }catch(e){ }
      codeObj = {'mode':'json', 'value': from.uicontrols }

    }
    if(editorState == 'embedding') { codeObj = {'mode':'html', 'value': from.embedding } }
    if(editorState == 'socket') { codeObj = {'mode':'python', 'value': from.socket } }
    if(editorState == 'plugins') { codeObj = {'mode':'pluginlist', 'value': from.plugins } }
    return codeObj
  }

  useEffect(() => {
    setWipCode(props.code);
    setCurrentCode(getCurrentCode(props.code, editorState));
    setError(props.errorDetails)
  }, [props]);


  const handleCodeChange = (event) => {
    setEditorState(event.target.value)
  }

  useEffect(() => {
    setCurrentCode(getCurrentCode(wipCode, editorState));
  }, [editorState])


  const handlePluginClick = (event) => {
    setWipCode((prevWipCode) => {
      prevWipCode.plugins = prevWipCode.plugins.map(obj => {
        return (event.target.value == obj.name) ? {...obj, 'active' : !obj.active } : obj
      })
      return prevWipCode
    })
  }

  const validateUiJSON = (uicontrols) => {
    let msg = {'status': 'ok', 'msg':'Valid spesification'}
    let parsedUI

    try{
      parsedUI = JSON.parse(uicontrols)
    }catch{
      return {'status': 'error', 'msg': 'uiControls is not valid json'}
    }
    
    if(!Array.isArray(parsedUI)){ 
      return {'status': 'error', 'msg': 'Root element must be an array'}
    }

    for(let controllerGroup of parsedUI){
      if(!('controllers' in controllerGroup)){
        return {'status': 'error', 'msg': 'Each controller group must have key "controllers"'} 
      }
      if(!('label' in controllerGroup)){
        msg = {'status': 'warning', 'msg': 'A controller group has no label'} 
      }
      if(!Array.isArray(controllerGroup['controllers'])){
        return {'status': 'error', 'msg': '"controllers" must be an array'}  
      }
      for(let controller of controllerGroup['controllers']){
        for(let k of ['type', 'name']) {
          if(!(k in controller)){
            return {'status': 'error', 'msg': 'controller key '+k+' is mandatory'}    
          }
        }
        if(!['radio','checkbox','number','select'].includes(controller['type'])){
          return {'status': 'error', 'msg': 'controller type must be one of "number", "select", "radio", "checkbox"'}    
        }
        if(controller['type'] != 'number' && !('c' in controller)){
          return {'status': 'error', 'msg': 'controller with type '+controller['type']+' requires element c'}
        }
        if(controller['type'] != 'number' && !Array.isArray(controller['c'])){
          return {'status': 'error', 'msg': 'c must be an array'}
        }
        
        if(controller['type'] != 'number') {
          for(let c of controller['c']){
            if(!('name' in c)){
                return {'status': 'error', 'msg': 'Each element of c requires key "name"'}
            }
            if(!('label' in c)){
                return {'status': 'error', 'msg': 'Each element of c requires key "label"'}
            }
          }
        }
      }
    }
    return msg

  }


  const saveCode = (options) => {
    
    let validationReturn = validateUiJSON(wipCode.uicontrols)
    if(validationReturn.status == 'error'){
      alert(validationReturn.msg)
      return
    }
    
    if(!options){ options={}}

    API({
      endpoint: '/api/projects/' + params.projectSlug + '/interfaces/' + params.interfaceSlug + '/code',
      method: 'PUT',
      body: {
        ...wipCode,
        ...options,
        'plugins': wipCode.plugins.filter((p) => p.active).map((p) => p.name)
      },
      callback : () => window.location.reload(),
    })

  }

  const editorStateAceMode = {
    'backend':'python',
    'frontend':'javascript',
    'styles':'css',
    'uicontrols':'json',
    //'embedding':'html',
    'socket':'python',
    'plugins':'pluginlist',
  }

  const jsonEditorValidationError = () => {
      let valid = validateUiJSON(wipCode.uicontrols)
      let msgClass = (
        valid.status == 'error' ? 'message is-danger' :
        valid.status == 'warning' ? 'message is-warning' :
        valid.status == 'ok' ? 'message is-success' : ''
      )

      return <article className={ msgClass } >
          <div className="message-header">
            <p>{ valid.status }</p>
          </div>
          <div className="message-body">
            { valid.msg }
          </div>
      </article>
      }

  const editor =  <AceEditor 
        style={{ width: '100%'}}
        mode={currentCode.mode}
        theme='monokai'
        name='code-editor'
        onChange={(value) => { 
          setWipCode({...wipCode, [editorState]: value });
          setCurrentCode((prev) => ({...prev, "value":value }))
        } }
        fontSize={14}
        highlightActiveLine={true}
        value={currentCode.value}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: true,
            behavioursEnabled: false,
            showLineNumbers: true,
            showInvisibles: true,
            showPrintMargin: false,
            showGutter: true,
            fixedWidthGutter: true,
            tabSize: 4,

        }}
       />
  
  return (
    <div className={["rm-codeoverlay-master","card","is-fluid", props.hidden ? "is-hidden" : '', props.dual ? "is-dual-left" : ''].join(' ')}>
      <header className="card-header">
        <div className="card-header-title">
          <div className="select is-normal">
            <select onChange={handleCodeChange} value={editorState} >
              <option value="backend">Backend</option>
              <option value="frontend">Frontend</option>
              <option value="styles">Styles</option>
              <option value="uicontrols">Ui-controls</option>
              { /* <option value="embedding">Embedding</option> */}
              <option value="socket">Sockets</option>
              <option value="plugins">Plugins</option>
            </select>
          </div>
          { props.isCached && <p className="">&nbsp;This view is cached. Disable cache to edit code.</p> }
        </div>
        <button className="card-header-icon" aria-label="close" onClick={props.toggleFunction}>
          <span className="icon">
            <i className="fas fa-window-close" aria-hidden="true"></i>
          </span>
        </button>
      </header>
     
      <div className="card-content">
      { editorState == 'uicontrols' && jsonEditorValidationError() }

      { !props.isCached && (currentCode.mode!='pluginlist' ?

        editor
        :
        <table className="card-table table is-hoverable is-striped">
        <thead>
          <tr><th>Plugin</th><th>Active</th></tr>
        </thead>
        <tbody>
        {
       wipCode.plugins.map((plugin,i) => (
          <tr key={i} className="">
          <td>{ plugin.name }</td>
          <td><input type="checkbox" defaultChecked={plugin.active} value={plugin.name} onChange={handlePluginClick} /></td>
          </tr>
          ))
        }
        </tbody>
        </table>
     )}
     
     </div>

     <ErrorDisplay error={error} />
     
     <footer className="card-footer">
      <a className="card-footer-item is-primary" onClick={() => saveCode({'pull':true}) }>Unpublish</a>
      <a className="card-footer-item is-primary" onClick={() => saveCode({'revert':true})}>Revert to published</a>
      <a className="card-footer-item is-primary" onClick={() => saveCode({'launch':true})}>Save and publish</a>
      <a className="card-footer-item is-primary" onClick={() => saveCode()}>Save changes</a>
     </footer>
      
     </div>
  );
}


