import React, { useEffect, useState } from 'react';




export const BulmaInputText = (props) => (
  <div className="field">
    <label className="label">{ props.name }</label>
    <div className="control">
      <input 
        id={props.id}
        className="input"
        type="text"
        placeholder="" 
        value={ props.value } 
        defaultValue={props.defaultValue} 
        onChange={props.onChange} 
        disabled={props.disabled}
        required={props.required}
        pattern={props.pattern}
        title={props.title}
       />
    </div>
  </div>)


export const BulmaTextArea = (props) => (
  <div className="field">
    <label className="label">{ props.name }</label>
    <div className="control">
      <textarea className="textarea" placeholder="" value={props.value} defaultValue={props.defaultValue} onChange={props.onChange}></textarea>
    </div>
  </div>)



export const BulmaPrompt = (props) => {

  const handleButtonClick = (event) => {
    props.callback(event.target.value)
    props.toggleVisible()
  }

  return (
  <div className={['modal', props.visible ? "is-active" : 'is-hidden'].join(' ')} >
  <div className="modal-background"></div>
  <div className="modal-card">
    <header className="modal-card-head">
      <p className="modal-card-title">{ props.title }</p>
      <button className="delete" aria-label="close"></button>
    </header>
    <section className="modal-card-body">
    { props.text }
    </section>
    <footer className="modal-card-foot">
      { props.buttons.map(button => {
        <button className="button" value={ button.value } onClick={handleButtonClick} >{ button.text }</button>
      }) }
    </footer>
  </div>
  </div>
  )
}



//***************************NAVBAR BUTTON TEMPLATE**********************//

export const IconButton = (props) => {
  return (<a className={["button", props.className].join(' ')} disabled={props.disabled} onClick={props.onClick}>
              <span className="icon is-small">
              <i className={props.icon}></i>
              </span>
         </a>)
}

export const ServerErrorMessage = (message) => {
  return `<div class="container server-error-message">
  <article class="block content message is-danger">
    <div class="message-header">
      <p>Server error</p>
    </div>
    <div class="message-body">
      ${message}
    </div>
  </article>
  </div>`
}